import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import { useState, useEffect } from "react";
import Login from "./components/Login";
import Register from "./components/Register";
import "bootstrap/dist/css/bootstrap.min.css";
import { API_URL, secret_key } from "./General";
import axios from "axios";
import CryptoJS from 'crypto-js';
import Home from "./components/Home";
import ResetPassword from "./components/ResetPassword";
import Invoices from "./components/Invoices";
import Receipts from "./components/Receipts";
import UserWayWidget from "./components/UserWayWidget";
import Connected from "./components/Connected";
import TextToVoice from './components/TextToVoice';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null); // Declare userData state
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const storedData = localStorage.getItem('user');

    if (storedData) {
      var bytes = CryptoJS.AES.decrypt(storedData, secret_key);
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setUserData(decryptedData); // Set the userData state here
    }

    const encryptedToken = localStorage.getItem('token');
    let decryptedToken = '';

    if (encryptedToken) {
      try {
        const decryptedBytes = CryptoJS.AES.decrypt(encryptedToken, secret_key);
        decryptedToken = decryptedBytes.toString(CryptoJS.enc.Utf8);
      } catch (error) {
        console.log('Error.');
      }
    }

    const _token = `Bearer ${decryptedToken}`;

    if (_token) {
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_URL}/verify`,
        headers: {
          'Authorization': _token
        }
      };

      axios.request(config)
        .then((response) => {
          if (response.data.statusCode === 401) {
            setIsAuthenticated(false);
          } else {
            setIsAuthenticated(true);
          }
        })
        .catch((error) => {
          setIsAuthenticated(false);
        })
        .finally(()=>{
          setLoading(false);
        });
    } else {
      setIsAuthenticated(false);
      setLoading(false);
    }
  }, []);

  const Layout = ({ children }) => (
    <>
        <TextToVoice />
        {children}
    </>
  );

  return (
    <>
      <BrowserRouter>
      <UserWayWidget />
      
      {loading ? (
          ""
        ) : (
        <Routes> 
          <Route path="/" element={isAuthenticated ? <Layout><Home /></Layout> : <Navigate to="/login" replace />} />
          <Route path="invoices" element={isAuthenticated ? <Layout><Invoices /></Layout> : <Navigate to="/login" replace />} /> 
          <Route path="receipts" element={isAuthenticated ? <Layout><Receipts /></Layout> : <Navigate to="/login" replace />} />   
          <Route path="devices" element={isAuthenticated ? <Layout><Connected /></Layout> : <Navigate to="/login" replace />} />             
          <Route
            path="/login"
            element={
              isAuthenticated ? <Navigate to="/" replace /> : <Layout><Login /></Layout>
            }
          />           
          <Route path="/register" element={<Layout><Register /></Layout>}/>
          <Route path="/password_reset" element={<Layout><ResetPassword /></Layout>}/>
          {/* <Route path="/otp_steps" element={<PasswordReset />}/> */}
          <Route
            path="*"
            element={
              <Navigate to="/login" replace />
            }
          />
        </Routes>
         )}
      </BrowserRouter>
    </>
  );
}

export default App;
