import React, { useState, useEffect } from 'react';
import ttv from '../assets/tts.png';
import microphone from '../assets/microphone.svg';
import microphoneoff from '../assets/microphoneoff.svg';

const TextToVoice = () => {
    const [isTTSActive, setIsTTSActive] = useState(false);
    const [ttsPopupVisible, setTtsPopupVisible] = useState(false);
    let speechUtterance = null;

    useEffect(() => {
        if (isTTSActive) {
            document.addEventListener('mouseup', handleSpeakSelectedText);
            announceActivation();
        } else {
            stopSpeech();
            document.removeEventListener('mouseup', handleSpeakSelectedText);
        }

        // Cleanup function
        return () => {
            stopSpeech();
            document.removeEventListener('mouseup', handleSpeakSelectedText);
        };
    }, [isTTSActive]);

    const toggleTTS = () => {
        const newState = !isTTSActive;
        setIsTTSActive(newState);
        localStorage.setItem('isTTSActive', newState); // Persist state in local storage
        
        // Hide the popup when microphone is clicked
        setTtsPopupVisible(false);
        
        if (newState) {
            announceActivation();
        } else {
            stopSpeech(); // Stop any ongoing speech when deactivating TTS
        }
    };

    const handleSpeakSelectedText = () => {
        stopSpeech();

        const selectedText = window.getSelection().toString();
        if (selectedText) {
            const utterance = new SpeechSynthesisUtterance(selectedText);
            utterance.lang = 'en-US';
            window.speechSynthesis.speak(utterance);
        }
    };

    const announceActivation = () => {
        stopSpeech();
        const announcement = new SpeechSynthesisUtterance("Text to Speech activated.");
        announcement.onend = readFromTop;
        window.speechSynthesis.speak(announcement);
    };

    const readFromTop = () => {
        stopSpeech();
        const allText = document.body.innerText;
        if (allText) {
            speechUtterance = new SpeechSynthesisUtterance(allText);
            speechUtterance.lang = 'en-US';
            window.speechSynthesis.speak(speechUtterance);
        }
    };

    const stopSpeech = () => {
        window.speechSynthesis.cancel();
    };

    const togglePopupVisibility = () => {
        setTtsPopupVisible(prevState => !prevState);
    };

    return (
        <>
            <div onClick={togglePopupVisibility}>
                <img className="Logo texttovoiceimg" alt="Logo" src={ttv} height="42px" width="42px" />
            </div>

            {ttsPopupVisible && (
                <div
                    id="ttsPopup"
                    style={{
                        position: 'fixed',
                        bottom: '70px',
                        right: '25px',
                        background: 'white',
                        padding: '10px',
                        borderRadius: '8px',
                        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                        textAlign: 'center'
                    }}
                >
                    <p onClick={toggleTTS} style={{ cursor: 'pointer' }}>
                        <img
                            src={isTTSActive ? microphone : microphoneoff}
                            alt="Microphone Icon"
                            style={{ height: '20px' }}
                        />
                    </p>
                </div>
            )}
        </>
    );
};

export default TextToVoice;
