import React, {useEffect, useState} from 'react'
import avatar from '../assets/Mawingubluelogo.png'
import { API_URL, secret_key, currentYear, saveLogs} from '../General';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import {useNavigate} from "react-router-dom"
import { useTranslation } from "react-i18next";
import Langpage from './Langpage';

function Connected() {
  const storedData = localStorage?.getItem('user');
  const [invoices, setInvoices] =useState([])
  const [numPages, setNumPages] = useState(null);
  const [devices, setDevices]=useState([]);

  if (storedData) {
    var bytes = CryptoJS.AES.decrypt(storedData, secret_key);
    var logeduserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleGoBack = () => {
    navigate(-1); // Go back one step in the navigation history
  };

  const custno = logeduserData.Customer_no
  const getCustomerDetails =() =>{
      let config = {
        method: 'post',
        maxBodyLength: Infinity, 
        url: `${API_URL}/get_customer_details`,
        headers: { 
          'Content-Type': 'application/json'
        },
          data : {
            "custno": custno
          }
      };
      
      axios.request(config)
      .then((response) => {
        let config1 = {
          method: 'post',
          maxBodyLength: Infinity, 
          url: `${API_URL}/connected_devices`,
          headers: { 
            'Content-Type': 'application/json'
          },
            data : {
              "ipaddress": response.data.ip_address
            }
        };
        
        axios.request(config1)
        .then((response) => {
          setDevices(response.data)
        })
        .catch((error) => {
          console.log(error);
        });
      })
      .catch((error) => {
        console.log(error);
      });
    }

    useEffect(() => {
      getCustomerDetails();
    }, [custno])
  
  return (
    <div className="overlap-group1">
      
      <div className="logoutlang">
          <button onClick={handleGoBack} className="back-button" >
            &lt;&lt; {t('back')}
          </button>
          <Langpage />
      </div>
      <img src={avatar} alt='' />
      <div className="div23">
      <div className="text1-wrapper">{t('connected_devices')}</div>    
      <div className="wraprshead">        
        <div className="text1-wrapper-3">{t('id')}</div>
        <div className="text1-wrapper-3">{t('device_name')}</div>
        <div className="text1-wrapper-3">{t('mac_address')}</div>
      </div>
      
      {devices.map((device)=>{
        if(device.id){
          return(
            <div className="wraprs" key={device.id}>
            <div className="list-item">{device.id}</div>
            <div className="list-item">{device.device}</div>
            <div className="list-item">{device.mac}</div>
            
          
        </div>)
        }else{
        return(
          <div className="wraprs" key={device}>
            <div className="">{device}</div>      
          </div>
    
        )
      }
      })}
      </div>
      <div className="copyright">{t('copyright')} © {currentYear}-Mawingu</div>
    </div>
  )
}

export default Connected