import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  lng: localStorage.getItem('selectedLanguage') || 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
    resources: {
      en: {
        translation: {
          login: "Log in",
          welcome: "Welcome to the Self-Care Portal",
          enter_cust_no: "Enter Account number",
          cust_no: "Account Number",
          enter_pin: "Enter PIN",
          change_pin: "Change PIN",
          confirm_pin: "Confirm PIN",
          pin: "PIN",
          remember_me: "Remember me",
          forgot_pass: "Forgot Password?",
          resend_pin: "Resend Pin",
          new_user: "New user",
          support: "Support",
          callwhatsapp: "Whatsapp",
          email: "Email",
          logging: "Logging in...",
          copyright: "Copyright",
          submit: "Submit",
          submitting: "Submitting...",
          enter_phone_number: "Enter Phone Number or Email",
          phone_number: "Phone Number or Email",
          back: "back",
          reset_password: "Reset Password",
          reset: "Reset",
          back_to_login: "Back to Log In",
          sending: "Sending...",
          welcomestaff: "Welcome",
          account_number: "Account number",
          phone: "Phone",
          current_packages: "Current packages",
          renewal_date: "Renewal date",
          change: "Change",
          wifi_password: "WiFi password",
          renew: "Renew",
          subscription: "subscription",
          download: "Download",
          invoice: "Invoice",
          receipts: "Receipts",
          logout: "Logout",
          change_wifi_password: "Change WIFI password",
          manage_wifi: "Manage WIFI",
          pay_now: "Pay Now",
          make_payment: "Make Payment",
          current_wifi_password: "Current WIFI password",
          enter_new_wifi_password: "Enter New WIFI password",
          confirm_wifi_password: "Confirm WIFI password",
          renew_subscription: "Renew subscription",
          phone_number_maessage: "Phone number(change if this is not paying number)",
          package_upgrade: "Package(upgrade or downgrade package)",
          subscription_amount: "Subscription amount",
          payment_receipts: "Payment Receipts",
          receipt_number: "Receipt number",
          date: "Date",
          description: "Description",
          amount: "Amount",
          action: "Action",
          view: "View",
          invoices: "Invoices",
          invoice_number: "Invoice number",
          wifi_name: "WIFI Name(Edit to Change)",
          connected_devices: "Connected Devices",
          refer_customer: "Refer a Customer",
          region: "Region",
          town: "Town",
          name: "Name",
          enter_potential_customer_name: "Enter potential customer name",
          enter_potential_customer_region: "Enter potential customer region",
          enter_potential_customer_town: "Enter potential customer town",
          enter_potential_customer_contact: "Enter potential customer contact",
          id: "Id",
          device_name: "Device Name",
          mac_address: "Mac Address"
        },
      },
      so: {
        translation: {
          login: "Soo gal",
          welcome: "Ku soo dhawoow iskuxirka Is-daryeelka",
          enter_cust_no: "Geli Lambarka ciwaankaaga",
          cust_no: "Lambarka ciwaankaaga",
          enter_pin: "Geli lambarka sirta ah",
          change_pin: "Beddel Pin ka",
          confirm_pin: "Xaqiiji Pin ka",
          pin: "Erayga sirta ah",
          remember_me: "I xasuuso",
          forgot_pass: "Ilowday erayga sirta ah?",
          resend_pin: "Dib udir pin ka",
          new_user: "Isticmaale cusub",
          support: "caawin",
          callwhatsapp: "Whatsapp",
          email: "Iimaylka",
          logging: "Galida...",
          copyright: "xuquuqda daabacaada",
          submit: "Gudbi",
          submitting: "Gudbinta...",
          enter_phone_number: "Geli Lambarka Taleefanka ama iimaylka",
          phone_number: "Lambarka Taleefanka ama iimaylka",
          back: "dib",
          reset_password: "Dib u deji erayga sirta ah",
          reset: "Dib u dajin",
          back_to_login: "Ku laabo galitaanka",
          sending: "Diraya...",
          welcomestaff: "Soo dhawoow",
          account_number: "Lambarka ciwaankaaga",
          phone: "Taleefanka",
          current_packages: "xirmada hadda",
          renewal_date: "Taariikhda cusboonaysiinta",
          change: "Beddel furaha",
          wifi_password: "sirta ah ee WiFi",
          renew: "Cusbooneysii",
          subscription: "isdiiwaangelinta",
          download: "Soo deji",
          invoice: "Qaansheegta",
          receipts: "Rasiidhada",
          logout:"Ka bax",
          change_wifi_password: "Beddel furaha sirta ah ee WiFi ga",
          manage_wifi: "Manage WIFI",
          pay_now: "Hadda bixi",
          make_payment: "Bixi lacagta",
          current_wifi_password: "Furaha sirta ah ee WiFi hadda",
          enter_new_wifi_password: "Geli furaha cusub ee WiFi ga",
          confirm_wifi_password: "Xaqiiji erayga sirta ah ee WiFi ga",
          renew_subscription: "Cusbooneysii isdiiwaangelinta",
          phone_number_maessage: "Lambarka taleefanka (beddel haddii kanu aanu ahayn lambarka lacag bixinta)",
          package_upgrade: "Xirmo (kor ama hoos u dhig xirmadha)",
          subscription_amount: "Qiimaha  isdiiwaangelinta",
          payment_receipts: "Rasiidhada lacag bixinta",
          receipt_number: "Lambarka rasiidka",
          date: "Taariikhda",
          description: "Sharaxaada",
          amount: "Qiimaha",
          action: "Ficil",
          view: "Fiiri/eeg",
          invoices: "Qaansheegyada",
          invoice_number: "Lambarka qaansheegta",
          wifi_name: "Magaca WIFI ga (wax ka beddel)",
          connected_devices: "Qalabka ku kuxarirsan",
          refer_customer: "Naguxir macaamiil",
          region: "Gobol",
          town: "Magaalada",
          name: "Magac",
          enter_potential_customer_name: "Geli magaca macmiilka suurtagalka ah",
          enter_potential_customer_region: "Geli gobolka macmiilka suurtagalka ah",
          enter_potential_customer_town: "Geli magaalada macmiilka suurtagalka ah",
          enter_potential_customer_contact: "Geli teleefanka macmiilka suurtagalka ah",
          id: "Id",
          device_name: "Magaca aalada",
          mac_address: "Mac Address"     
        }
      },
    },
  });

export default i18n;